<template>
    <app-dialog class="dialog-remove-team"
        v-if="show"
        v-model="show"
        
        :max-width="maxWidth"

        :loading="loading"
        :processing="processing"

        @closes="closes"
    >
        <template #head>
            <h3>Team removal</h3>

            <app-error v-model="errors.show" :message="errors.message" />
        </template>

        <template #form>
            <p>You have selected to delete "{{ team.Name }}".</p>
            <p>If this was the action that you want to do, please confirm your choice, or cancel and return to the page.</p>
        </template>

        <template #btns>
            <button class="btn btn-primary" @click.prevent="confirm" :disabled="processing">Confirm</button>
            <button class="btn btn-cancel" @click.prevent="close" :disabled="processing">Cancel</button>
        </template>
    </app-dialog>
</template>


<script>
import { mapGetters } from 'vuex'

import appDialog from '@/components/app-dialog'
import appError from '@/components/app-error'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:       { required: true },
        maxWidth: { type: [ Number, String ], default: 610 },
        team: { required: true }
    },

    components: {
        appDialog,
        appError,
    },

    data() {
        return {
            loading: false,
            processing: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    methods: {
        close() {
            this.show = false
        },

        closes(with_button) {
            this.$emit('closes', with_button)
        },

        confirm() {
            this.processing = true

            this.errors.show = false
            this.errors.message = ''

            const payload = {
                UUID: this.team.UUID,
            }

            this.$store.dispatch('removeTeam', payload)
                .then(() => {
                    this.processing = false

                    this.$emit('team-removed')

                    this.close()
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.processing = false
                })
        },
    },

    computed: {
        ...mapGetters([
            // 'current_spid',
        ]),

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog.dialog-remove-team {
    z-index: $z-index-dialog-upmost;

    .app-dialog-container {
        flex-shrink: 0;
        height: auto;
        max-height: none;

        .app-dialog-body {
            padding: 48px 80px;

            h3 {
                margin-bottom: 40px;
            }

            p {
                font-weight: normal;
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-dialog.dialog-remove-team  {
        .app-dialog-container {
            .app-dialog-body {
                padding: 64px 24px;

                form {
                    &:not(:last-child) {
                        margin-bottom: 0;
                    }
                }

                h3 {
                    margin-bottom: 16px;
                }
            }
        }
    }
}
</style>
