<template>
    <div class="app-teams-plates">
        <div class="items" ref="items">
            <div class="items-row" v-for="(items_row, index_row) in grid.items" :key="index_row">
                <div class="team" v-for="(team, index_col) in items_row" :key="index_col"
                    :style="grid.style"

                    @click="itemClick(team)"
                >
                    <div class="wrapper">
                        <div class="name">
                            <span>{{ team.name }}</span>
                        </div>

                        <div class="actions">
                            <button class="btn btn-table-action action-primary action-edit"     @click.stop="teamEdit(team)"></button>
                            <button class="btn btn-table-action action-secondary action-remove" @click.stop="teamRemove(team)"></button>
                        </div>
                    </div>
                    <div class="summary">
                        <div class="created">{{ team.created }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        teams: { type: [Object, Array], required: true },
    },

    data() {
        return {
            items: {
                width: null,
                margin: 20,
                max_items: 3,
                min_width: 250,
            }
        }
    },

    created() {
        this.initItemsWidth()

        window.addEventListener('resize', this.initItemsWidth)
    },

    methods: {
        initItemsWidth(without_next_tick) {
            if (without_next_tick) {
                const items = this.$refs.items

                if (items) {
                    this.items.width = window.innerWidth > this.$mobile_size
                        ? items.offsetWidth
                        : null
                }
            } else {
                this.$nextTick(() => {
                    this.initItemsWidth(true)
                })
            }
        },

        itemClick(team) {
            this.$emit('item-click', team)
        },

        teamEdit(team) {
            this.$emit('edit-team', team)
        },

        teamRemove(team) {
            this.$emit('remove-team', team)
        },
    },

    watch: {
        teams() {
            this.initItemsWidth()
        },
    },

    computed: {
        ...mapGetters([
            '$mobile_size',
        ]),

        teams_arr() {
            return Array.isArray(this.teams)
                ? this.teams
                : Object.values(this.teams)
        },

        count() {
            return this.teams_arr.length
        },

        grid() {
            let grid = {
                x: 0,
                y: 0,
                style: null,
                items: [],
            }
            
            if (this.count) {
                let x,
                    y,
                    style

                if (this.items.width) {
                    x     = Math.max(1, Math.min( this.items.max_items, Math.floor( this.items.width / (this.items.min_width + this.items.margin) ) ) )
                    y     = Math.ceil(this.count / x)
                    style = x > 1 ? { width: `${ (this.items.width / x) - this.items.margin }px` } : null
                } else {
                    x     = 1
                    y     = this.count
                    style = null
                }

                let items = []
                for (let row = 0; row < y; row++) {
                    let items_row = []

                    for (let col = 0; col < x; col++) {
                        const index = row * x + col

                        if (index < this.count) {
                            items_row.push( this.teams_arr[index] )
                        } else {
                            break
                        }
                    }

                    items.push(items_row)
                }

                grid = {
                    x,
                    y,
                    style,
                    items,
                }
            }

            return grid
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.initItemsWidth)
    },
}
</script>

<style lang="scss">
.app-teams-plates {
    .items {
        .items-row {
            max-width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            margin: 30px 0 0;

            &:first-child {
                margin: 0;
            }
        }
    }

    .team {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        color: var(--color-personal-info-secondary);
        font-size: 16px;
        line-height: 24px;
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-dropdown-bg);
        box-shadow: var(--box-shadow-primary);
        cursor: pointer;

        &:hover {
            background-color: var(--color-dropdown-bg-hover);
        }

        .wrapper {
            display: flex;
            width: 100%;

            .name {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-grow: 1;
                text-transform: uppercase;
                color: var(--color-personal-info-primary);

                span {
                    word-break: break-word;

                    @include line-clamp(24px, 2);
                }

                &:before {
                    flex-shrink: 0;
                    content: "";
                    display: inline-block;
                    width: 48px;
                    height: 48px;
                    background: url('./img/team.svg') no-repeat center;
                    background-size: contain;
                    margin-right: 16px;
                }
            }

            .actions {
                display: flex;
                align-items: center;

                .btn {
                    margin-right: 16px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .summary {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: 16px;

            .members,
            .created {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                
                &:before {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }
            }

            .members {
                &:before {
                    color: var(--color-icon-calm);

                    @include icon($icon-member);
                }
            }

            .created {
                &:before {
                    color: var(--color-icon-calm);

                    @include icon($icon-calendar);
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-teams-plates {
        .items {
            .items-row {
                margin: 15px 0 0;
            }
        }

        .team {
            font-size: 14px;
            padding: 16px;
        }
    }
}
</style>