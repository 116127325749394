<template>
    <app-dialog class="dialog-edit-team"
        v-if="show"
        v-model="show"
        
        :max-width="maxWidth"

        :loading="loading"
        :processing="processing"

        @closes="closes"
    >
        <template #head>
            <h3>Edit a team</h3>

            <app-error v-model="errors.show" :message="errors.message" />
        </template>

        <template #form>
            <app-input
                v-model="name"

                label="Team name"

                :error="errors.fields.name"

                @change="errors.fields.name = null"
            />

            <app-checkbox-group v-if="with_teams_roles"
                v-model="roles"

                label="Team roles"

                :options="teams_roles"
                :options-disabled="roles_auto_add"

                key-value="Name"
                key-title="Title"

                @input="initRolesAutoAdd"
            />
        </template>

        <template #btns>
            <button class="btn btn-primary" @click.prevent="confirm" :disabled="processing || !selected_at_least_one_role || !has_changes">Save</button>
            <button class="btn btn-cancel" @click.prevent="close" :disabled="processing">Cancel</button>
        </template>
    </app-dialog>
</template>


<script>
import { mapGetters } from 'vuex'

import appDialog from '@/components/app-dialog'

import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appCheckboxGroup from '@/components/app-checkbox-group'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:    { required: true                         },
        maxWidth: { type: [ Number, String ], default: 840 },
        team:     { required: true                         },
    },

    components: {
        appDialog,

        appError,
        appInput,
        appCheckboxGroup,
    },

    data() {
        return {
            name: '',
            roles: [],
            roles_auto_add: {},

            loading: false,
            processing: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getTeamsRoles()

            this.name = this.team.Name && typeof this.team.Name == 'string' ? this.team.Name : ''
            this.roles = this.team.Roles && Array.isArray(this.team.Roles) ? this.team.Roles : []
        },

        getTeamsRoles() {
            this.loading = true

            const stop_loading = () => {
                this.initRolesAutoAdd(this.roles)
                this.loading = false
            }

            this.$store.dispatch('getTeamsRoles').then(stop_loading).catch(stop_loading)
        },

        close() {
            this.show = false
        },

        closes(with_button) {
            this.$emit('closes', with_button)
        },

        initRolesAutoAdd(selected) {
            this.roles_auto_add = {}
            
            if (selected && selected.length) {
                // If we have a selection, lets walk through
                for (let thiskeyindex in selected) {
                    let thiskey = selected[thiskeyindex]
                    // Check if the current item is meant to have other roles auto-added
                    if (this.teams_roles && this.teams_roles[thiskey] && this.teams_roles[thiskey].AutoAdd && this.teams_roles[thiskey].AutoAdd.length) {
                        for(let depkeyindex in this.teams_roles[thiskey].AutoAdd) {
                            const role_auto_add = this.teams_roles[thiskey].AutoAdd[depkeyindex]
                            this.roles_auto_add[ role_auto_add ] = { checked: true }
                        }
                    }
                }
            }
        },

        confirm() {
            if (this.validate()) {
                this.processing = true

                const req = {
                    UUID: this.team.UUID,
                    SPID: this.current_spid,
                    Name: this.name.trim(),
                    Roles: this.roles,
                }

                if (Object.keys(this.roles_auto_add).length) {
                    for (const role in this.roles_auto_add) {
                        // Check that the role to auto-add isn't already added
                        if (!req.Roles.includes(role)) {
                            req.Roles.push(role)
                        }
                    }
                }

                this.$store.dispatch('api_auth/UpdateAuthRoleGroup', req)
                    .then(() => {
                        this.errors.show = false
                        this.errors.message = ''

                        this.$emit('team-saved')
                        this.processing = false
                        this.close()
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.processing = false
                    })
            }
        },

        validate() {
            this.errors.fields = {}

            let is_valid = true

            if (!this.name.trim()) {
                this.errors.fields.name = 'Team name can not be blank'
                is_valid = false
            }

            return is_valid
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'teams_roles',
        ]),

        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },

        with_teams_roles() {
            return this.teams_roles && Object.keys(this.teams_roles).length > 0
        },

        selected_at_least_one_role() {
            return this.roles.length > 0
        },

        has_changes() {
            const roles_count = this.team.Roles ? this.team.Roles.length : 0
            
            return this.name.trim() != (this.team.Name ? this.team.Name.trim() : '')
                || this.roles.length != roles_count
                || (
                    roles_count
                    && this.roles.sort().join('-').toLowerCase() != this.team.Roles.sort().join('-').toLowerCase()
                )
        },
    },
}
</script>

<style lang="scss">
.app-dialog.dialog-edit-team {
    z-index: $z-index-dialog-upmost;

    .app-dialog-container {
        flex-shrink: 0;
        height: auto;
        max-height: none;

        .app-dialog-body {
            padding: 48px 80px;

            .app-checkbox-group {
                .options {
                    .app-checkbox {
                        width: 200px;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-dialog.dialog-edit-team  {
        .app-dialog-container {
            .app-dialog-body {
                padding: 64px 24px;

                .app-checkbox-group {
                    margin-top: 8px;
                }
            }
        }
    }
}
</style>
