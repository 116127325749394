<template>
    <app-dialog class="dialog-add-team"
        v-if="show"
        v-model="show"
        
        :max-width="maxWidth"

        :loading="loading"
        :processing="processing"

        @closes="closes"
    >
        <template #head>
            <h3>Add a team</h3>

            <app-error v-model="errors.show" :message="errors.message" />
        </template>

        <template #form>
            <app-input
                v-model="team.name"

                label="Team name"

                :error="team.errors.name"

                @change="team.errors.name = null"
            />

            <app-checkbox-group v-if="with_teams_roles"
                v-model="team.roles"

                label="Team roles"

                :options="teams_roles"

                key-value="Name"
                key-title="Title"
            />
        </template>

        <template #btns>
            <button class="btn btn-primary" @click.prevent="confirm" :disabled="processing || !selected_at_least_one_role">Add</button>
            <button class="btn btn-cancel" @click.prevent="close" :disabled="processing">Cancel</button>
        </template>
    </app-dialog>
</template>


<script>
import { mapGetters } from 'vuex'

import appDialog from '@/components/app-dialog'

import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appCheckboxGroup from '@/components/app-checkbox-group'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:    { required: true                         },
        maxWidth: { type: [ Number, String ], default: 840 },
    },

    components: {
        appDialog,

        appError,
        appInput,
        appCheckboxGroup,
    },

    data() {
        return {
            team: {
                name: '',
                roles: [],

                errors: {},
            },

            loading: false,
            processing: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getTeamsRoles()
        },

        getTeamsRoles() {
            this.loading = true

            const stop_loading = () => { this.loading = false }

            this.$store.dispatch('getTeamsRoles').then(stop_loading).catch(stop_loading)
        },

        close() {
            this.show = false
        },

        closes(with_button) {
            this.$emit('closes', with_button)
        },

        confirm() {
            if (this.validate()) {
                this.processing = true

                const req = {
                    SPID: this.current_spid,
                    Name: this.team.name.trim(),
                    Roles: this.team.roles,
                }

                this.$store.dispatch('api_auth/AddAuthRoleGroup', req)
                    .then(team => {
                        this.errors.show = false
                        this.errors.message = ''

                        this.$emit('team-added')
                        this.processing = false
                        this.close()

                        this.$store.dispatch('addToast', {
                            message: `Team "${ team.Name }" was successfully created`,
                            type: 'success',
                            delay: 5000,
                        })
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.processing = false
                    })
            }
        },

        validate() {
            this.team.errors = {}

            let is_valid = true

            if (!this.team.name.trim()) {
                this.team.errors.name = 'Team name can not be blank'
                is_valid = false
            }

            return is_valid
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'teams_roles',
        ]),

        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },

        with_teams_roles() {
            return this.teams_roles && Object.keys(this.teams_roles).length > 0
        },

        selected_at_least_one_role() {
            return this.team.roles.length > 0
        },
    },
}
</script>

<style lang="scss">
.app-dialog.dialog-add-team {
    z-index: $z-index-dialog-upmost;

    .app-dialog-container {
        flex-shrink: 0;
        height: auto;
        max-height: none;

        .app-dialog-body {
            padding: 48px 80px;

            .app-checkbox-group {
                .options {
                    .app-checkbox {
                        width: 195px;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-dialog.dialog-add-team  {
        .app-dialog-container {
            .app-dialog-body {
                padding: 64px 24px;

                .app-checkbox-group {
                    margin-top: 8px;
                }
            }
        }
    }
}
</style>
