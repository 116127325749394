<template>
    <div class="page-teams container">
        <div class="row">
            <div class="col-8 col-tab-12">
                <app-loader v-if="loading" fixed-on="desktop" />

                <app-error v-model="errors.show" :message="errors.message" />

                <template v-if="loaded">
                    <app-placeholder v-if="empty" message="Teams not found" />

                    <template v-else>
                        <app-table v-if="viewType == 'table'"
                            class="teams-table"

                            :sort-value="filter.sort"
                            :sort-options="sort"
                            @sort-change="onSortChange"

                            :clickable="true"

                            :cols="cols"
                            :rows="teams"

                            :loading="loading"

                            @row-click="onTeamDetails"
                            @row-click-mobile="onTeamDetails"

                            @edit-team="onTeamEdit"
                            @remove-team="onTeamRemove"

                            ref="teams"
                        />

                        <app-teams-plates v-else
                            :teams="teams"

                            @item-click="onTeamDetails"
                            @edit-team="onTeamEdit"
                            @remove-team="onTeamRemove"
                        />

                        <app-pagination
                            v-if="pagination.total"

                            v-model="pagination.page"

                            :total="pagination.total"
                            :limit="pagination.limit"
                            :page-range="pagination.range"

                            @change="onPageChange"
                        />
                    </template>
                </template>
            </div>

            <div class="col-4 col-tab-12">
                <app-adding-banner
                    header="Add a team"
                    description="Add teams to simplify how permissions are allocated to users of the system"

                    image="team"

                    action="create"

                    @create="dialogs.create.show = true"
                />
            </div>
        </div>

        <dialog-add-team
            v-if="dialogs.create.show"
            v-model="dialogs.create.show"

            @team-added="onTeamAdded"
        ></dialog-add-team>

        <dialog-edit-team
            v-if="dialogs.edit.show"
            v-model="dialogs.edit.show"

            :team="dialogs.edit.team"

            @team-saved="onTeamSaved"
        ></dialog-edit-team>

        <dialog-remove-team
            v-if="dialogs.remove.show"
            v-model="dialogs.remove.show"

            :team="dialogs.remove.team"

            @team-removed="onTeamRemoved"
        ></dialog-remove-team>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appPlaceholder from '@/components/app-placeholder'
import appAddingBanner from '@/components/app-adding-banner'

import appTeamsPlates from './components/app-teams-plates'

import dialogAddTeam from './dialogs/dialog-add-team'
import dialogEditTeam from './dialogs/dialog-edit-team'
import dialogRemoveTeam from './dialogs/dialog-remove-team'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        search: { type: Object, default: () => ({ word: '', option: null })},

        viewType: { default: 'table' },
    },

    components: {
        appLoader,
        appError,
        appTable,
        appPagination,
        appPlaceholder,
        appAddingBanner,

        appTeamsPlates,

        dialogAddTeam,
        dialogEditTeam,
        dialogRemoveTeam,
    },

    data() {
        return {
            teams_list: [],

            filter: {
                word: '',
                previous: '',
                sort: {
                    field: 'Name',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'Name',           title: 'Name'       },
                { field: 'CreatedAtNanos', title: 'Created at' },
            ],

            cols: [
                { key: 'name',        title: 'Name',        sort: { field: 'Name' },          highlight: true,                },
                { key: 'roles',       title: 'Roles',                                         type: 'tags', class: 'bilinear' },
                { key: 'created',     title: 'Created at',  sort: { field: 'CreatedAtNanos' }                                 },

                { key: 'edit',        action: 'edit-team',   icon: 'edit',   behavior: 'detached',                            },
                { key: 'remove',      action: 'remove-team', icon: 'remove', behavior: 'detached',                            },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                create: {
                    show: false,
                },

                edit: {
                    show: false,
                    team: null,
                },

                remove: {
                    show: false,
                    team: null,
                },
            },

            loading: false,

            loaded: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    methods: {
        init(to, from) {
            const getTeams = () => { this.getTeams(to.params.page) }

            if (from.name && /^team(-|$)/i.test(from.name)) {
                this.loadTeamsFilter().then(getTeams).catch(getTeams)
            } else {
                this.$store.dispatch('clearTeamsFilter').then(getTeams).catch(getTeams)
            }
        },

        onTeamAdded() {
            this.getTeams(this.pagination.page)
        },

        loadTeamsFilter() {
            return this.$store.dispatch('loadTeamsFilter').then(loaded_filter => {
                let filter = {}

                for (const key in this.filter) {
                    filter[key] = key in loaded_filter
                        ? typeof this.filter[key] == 'string'
                            ? loaded_filter[key]
                            : {...this.filter[key], ...loaded_filter[key]}
                        : this.filter[key]
                }
                
                this.filter = filter
            }).catch(error => Promise.resolve(error))
        },

        getTeams(page, filter) {
            this.loading = true

            filter = {
                ...this.filter,
                ...this.search,
                ...filter
            }

            let params = {
                'SPID':                     this.current_spid,
                'IncludeInactive':          true,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     filter.sort.field,
                'SearchOptions.SortDesc':   filter.sort.order === 'desc' ? 1 : 0,
            }

            const word = filter.word.trim()

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.word = word
                this.filter.previous = word
            }

            this.$store.dispatch('api_auth/FindAuthRoleGroupsPaginated', params).then(response => {
                if (!response.AuthRoleGroups || !response.PageInfo) {
                    return Promise.reject(response)
                }

                this.teams_list = response.AuthRoleGroups

                this.pagination.page = response.PageInfo.PageNumber
                this.pagination.total = response.PageInfo.TotalItemCount

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.errors = {
                    show: false,
                    message: '',
                }

                this.loading = false

                this.loaded = true
            }).catch(error => {
                this.errors = {
                    show: true,
                    message: errMessage(error),
                }

                this.teams_list = []

                this.loading = false
            })
        },

        /**
         * Filtering
         */
        onSearch(search) {
            if (search.word.trim() != this.filter.word.trim()) {
                this.getTeams(this.pagination.page, { word: search.word })
            }
        },

        onSortChange(sort) {
            this.filter.sort = sort
            this.getTeams()
        },

        onPageChange(page) {
            this.getTeams(page)
        },

        /**
         * Team Details
         */
        onTeamDetails(team) {
            this.$router.push({ name: 'team', params: { uuid: team.uuid } })
        },

        formatRoleTags(roles) {
            if (!roles || roles.length == 0) {
                return []
            }
            let tags = []

            for (let i = 0, len = roles.length; i < len; i++) {
                const thisrole = roles[i]
                tags.push( thisrole in this.teams_roles
                    ? this.teams_roles[thisrole].Title
                    : thisrole[0].toUpperCase() + thisrole.slice(1).toLowerCase()
                )
            }

            return tags
        },
        
        onTeamEdit({ uuid }) {
            this.dialogs.edit.team = this.teams_list.find(team => team.UUID == uuid)
            this.dialogs.edit.show = true
        },

        onTeamSaved() {
            this.dialogs.edit.team = null
            this.dialogs.edit.show = false

            this.getTeams(this.pagination.page)
        },

        onTeamRemove({ uuid }) {
            this.dialogs.remove.team = this.teams_list.find(team => team.UUID == uuid)
            this.dialogs.remove.show = true
        },

        onTeamRemoved() {
            this.dialogs.remove.team = null
            this.dialogs.remove.show = false

            this.getTeams(this.pagination.page)
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'teams',
            'teams_roles',
        ]),

        teams() {
            return this.teams_list.map(team => ({
                uuid: team.UUID,

                name: team.Name,

                roles: this.formatRoleTags(team.Roles),

                created: formatDateNano(team.CreatedAtNanos, 'YYYY-MM-DD HH:mm:ss'),
            }))
        },

        empty() {
            return this.teams_list.length == 0
        },
    },

    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },
}
</script>

<style lang="scss">
.page-teams {
    padding-bottom: 80px;
    
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .teams-table {
        @include table-cols-width((180px, 350px, 200px, 48px, 48px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-teams {
        .teams-table {
            @include table-cols-width((160px, 300px, 150px, 48px, 48px), true);
        }

        .app-adding-banner {
            margin-top: 30px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-teams {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .teams-table {
            @include table-cols-width-mobile((75px, 180px, 24px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }

        .app-adding-banner {
            margin-top: 15px;
        }
    }
}
</style>